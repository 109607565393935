import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  ActivityIndicator
} from "react-native";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Moment from "moment";
import Util from "src/utility";
import SearchBar from "src/components/searchBar";
import Icon from "src/components/Icon";

const { height, width } = Glob.get("dimensions");

const ProductItem = ({ product }) => (
  <View style={styles.productItem}>
    <Text style={styles.productName}>
      {product.name} {product.quantity > 1 && `(Quantity: ${product.quantity})`}{" "}
      •{" "}
      <Text style={styles.productDetails}>
        ${(product.amountTotal / 100).toFixed(2)}
      </Text>
    </Text>
    {product.description && (
      <Text style={styles.productDescription}>{product.description}</Text>
    )}
  </View>
);

const SubscriptionChargeItem = ({ name, recurringText }) => (
  <View style={styles.productItem}>
    <Text style={styles.productDescription}>
      <Text style={{ fontWeight: "bold" }}>{name}</Text> payment (
      {recurringText})
    </Text>
  </View>
);

const OrderCard = ({ order }) => {
  const [isLoading, setIsLoading] = useState(false);
  const questionResponses = JSON.parse(
    order.lineItems[0].questionResponses || "{}"
  );
  const isRecurring = order.lineItems[0].recurring;
  const { isSubscriptionCharge } = order;

  const isCanceled = !!order.canceledAt;
  const cancelationMessage = order.canceledAt
    ? `Subscription canceled on ${Moment(order.canceledAt).format(
        "MMM D, YYYY"
      )}`
    : "";

  const getRecurringText = (recurring) => {
    const { interval, interval_count } = recurring;
    const count = interval_count || 1;

    if (count === 1) {
      // Special case for daily, otherwise add 'ly' suffix
      const intervalSuffix = interval === "day" ? "daily" : `${interval}ly`;
      return `Billed ${intervalSuffix}`;
    }

    return `Billed every ${count} ${interval}s`;
  };

  const handleManageSubscription = async () => {
    setIsLoading(true);
    try {
      const response = await Database.stripeConnectedAccountCreateCustomerPortalSession(
        order.customerID
      );
      if (response.success) {
        await Util.openURL(response.customerPortalSessionURL);
      } else {
        Util.alert(
          "Error",
          "Unable to open subscription management portal. Please try again later."
        );
      }
    } catch (error) {
      Util.alert(
        "Error",
        "Unable to open subscription management portal. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <View style={styles.orderCard}>
      <View style={styles.orderHeader}>
        <Text style={styles.orderDate}>
          {Moment(order.timestamp).format("MMM D, YYYY h:mm A")}
        </Text>
        <View style={styles.priceContainer}>
          {isSubscriptionCharge && (
            <Icon
              icon="47b848ee-e819-41b8-bc53-a9e02d2a37f4"
              size={16}
              color="#718096"
              style={{ marginRight: 4 }}
            />
          )}
          <Text
            style={[
              styles.orderPrice,
              isSubscriptionCharge && styles.subscriptionChargePrice
            ]}
          >
            ${(order.totalPrice / 100).toFixed(2)}
          </Text>
        </View>
      </View>

      {isCanceled && (
        <View style={styles.cancellationBanner}>
          <Icon
            icon="ca34233d-eb11-4855-805f-bb7b4f8e1fcb"
            size={14}
            color="#E53E3E"
            style={{ marginRight: 4 }}
          />
          <Text style={styles.cancellationText}>{cancelationMessage}</Text>
        </View>
      )}

      <View style={styles.productListWrapper}>
        {order.lineItems.map((item, index) => {
          if (isSubscriptionCharge) {
            return (
              <SubscriptionChargeItem
                key={`${order.sessionID}-${index}`}
                name={item.product.name}
                recurringText={(
                  getRecurringText(item.recurring) || ""
                ).toLowerCase()}
              />
            );
          }
          return (
            <ProductItem
              key={`${order.sessionID}-${index}`}
              product={{
                ...item.product,
                quantity: item.quantity,
                amountTotal: item.amountTotal
              }}
            />
          );
        })}
      </View>
      {!isSubscriptionCharge && Object.keys(questionResponses).length > 0 && (
        <View style={styles.questionResponses}>
          {Object.entries(questionResponses).map(
            ([question, response], index) => (
              <View key={index} style={styles.questionResponseItem}>
                <Text style={styles.question}>{question}</Text>
                <Text style={styles.response}>{response}</Text>
              </View>
            )
          )}
        </View>
      )}
      {isRecurring && (
        <View
          style={
            isSubscriptionCharge
              ? styles.subscriptionChargeBanner
              : styles.subscriptionBanner
          }
        >
          <View style={styles.buttonContainer}>
            {order.receiptUrl && (
              <TouchableOpacity
                style={styles.receiptButton}
                onPress={() => Util.openURL(order.receiptUrl)}
              >
                <Text style={styles.receiptButtonText}>Receipt</Text>
              </TouchableOpacity>
            )}
            {order.customerID && (
              <TouchableOpacity
                onPress={handleManageSubscription}
                style={styles.receiptButton}
                disabled={isLoading}
              >
                {isLoading ? (
                  <ActivityIndicator size="small" color="#4A5568" />
                ) : (
                  <Text style={styles.receiptButtonText}>
                    Manage Subscription
                  </Text>
                )}
              </TouchableOpacity>
            )}
          </View>
          {!isSubscriptionCharge && (
            <Text style={styles.subscriptionText}>
              {getRecurringText(isRecurring)}
            </Text>
          )}
        </View>
      )}
      {!isRecurring && order.receiptUrl && (
        <TouchableOpacity
          style={styles.receiptButton}
          onPress={() => Util.openURL(order.receiptUrl)}
        >
          <Text style={styles.receiptButtonText}>Receipt</Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

export default function CommerceCustomerOrderHistory({ navigation, route }) {
  const { commerceOrders: orders = [] } = route.params;
  const [allOrdersAndCharges, setAllOrdersAndCharges] = useState(orders);
  const [filteredOrders, setFilteredOrders] = useState(orders);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Analytics.logEvent("view_commerceCustomerOrderHistory");

    // Fetch subscription charges for all subscription orders
    const fetchSubscriptionCharges = async () => {
      const subscriptionOrders = orders.filter(
        (order) => order.mode === "subscription" && order.subscriptionID
      );

      if (subscriptionOrders.length < 1) {
        setIsLoading(false);
        return;
      }

      const subscriptionsPromises = subscriptionOrders.map((order) =>
        Database.fetchStripeConnectedAccountSubscriptionDetails(
          order.subscriptionID
        )
          .then((details) => ({
            ...details,
            charges: details.charges
              .filter((charge) => charge.status === "paid")
              .sort((a, b) => new Date(a.date) - new Date(b.date))
              .slice(1)
              .map((charge) => ({
                timestamp: charge.date,
                totalPrice: charge.amount,
                sessionID: charge.id,
                lineItems: order.lineItems,
                isSubscriptionCharge: true,
                receiptUrl: charge.receiptUrl,
                pdfUrl: charge.pdfUrl,
                status: charge.status
              }))
          }))
          .catch((error) => {
            console.error(`Failed to fetch subscription details: ${error}`);
            return { charges: [] };
          })
      );

      const subscriptionsResults = await Promise.all(subscriptionsPromises);

      const charges = subscriptionsResults.flatMap((result) => result.charges);

      // Create a map of subscription details for quick lookup
      const subscriptionDetailsMap = subscriptionsResults.reduce(
        (acc, subscription) => {
          acc[subscription.subscriptionID] = subscription;
          return acc;
        },
        {}
      );

      // Add subscription details to orders and combine with charges
      const allOrders = [
        ...orders.map((order) => {
          if (order.subscriptionID) {
            const subscriptionDetails =
              subscriptionDetailsMap[order.subscriptionID];
            return {
              ...order,
              canceledAt: subscriptionDetails?.canceledAt,
              cancelAtPeriodEnd: subscriptionDetails?.cancelAtPeriodEnd,
              currentPeriodEnd: subscriptionDetails?.currentPeriodEnd
            };
          }
          return order;
        }),
        ...charges
      ].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      setIsLoading(false);
      setFilteredOrders(allOrders);
      setAllOrdersAndCharges(allOrders);
    };

    fetchSubscriptionCharges();
  }, []);

  const createSearchableText = (order) => {
    const orderDate = Moment(order.timestamp).format("MMM D, YYYY h:mm A");
    const orderPrice = (order.totalPrice / 100).toFixed(2);
    const productNames = order.lineItems
      .map((item) => item.product.name)
      .join(" ");
    const productDescriptions = order.lineItems
      .map((item) => item.product.description)
      .join(" ");
    const questionResponses = JSON.parse(
      order.lineItems[0].questionResponses || "{}"
    );
    const questionResponsesText = Object.entries(questionResponses)
      .map(([question, response]) => `${question}: ${response}`)
      .join(" ");
    return `${orderDate} ${orderPrice} ${productNames} ${productDescriptions} ${questionResponsesText}`.toLowerCase();
  };

  const handleSearch = (query) => {
    if (query.trim() === "") {
      setFilteredOrders(allOrdersAndCharges);
    } else {
      const searchResults = orders.filter((order) =>
        createSearchableText(order).includes(query.toLowerCase())
      );
      setFilteredOrders(searchResults);
    }
  };

  if (isLoading) {
    return (
      <View style={[styles.pageContent, { backgroundColor: "white" }]}>
        <NavBar navigation={navigation} text="Payment History" />
        <ScrollView
          style={{ paddingHorizontal: 20, width }}
          scrollIndicatorInsets={{ right: 1 }}
        >
          <ActivityIndicator size="large" style={{ marginTop: 40 }} />
        </ScrollView>
      </View>
    );
  }

  if (orders?.length < 1)
    return (
      <View style={[styles.pageContent, { backgroundColor: "white" }]}>
        <NavBar navigation={navigation} text="Payment History" />
        <ScrollView
          style={{ paddingHorizontal: 20, width }}
          scrollIndicatorInsets={{ right: 1 }}
        >
          <Image
            source={Glob.get("cardboardBoxOpen")}
            style={{
              height: height * 0.3,
              width: height * 0.3,
              resizeMode: "contain",
              alignSelf: "center"
            }}
          />
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 18,
              textAlign: "center"
            }}
          >
            You haven't purchased anything yet.
          </Text>
        </ScrollView>
      </View>
    );

  return (
    <View style={styles.pageContent}>
      <NavBar navigation={navigation} text="Payment History" />
      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.scrollViewContent}
      >
        <View style={{ width: "100%", alignItems: "center", marginBottom: 12 }}>
          <SearchBar
            placeholder="Search orders..."
            onChangeText={handleSearch}
          />
        </View>
        {filteredOrders.map((order) => (
          <OrderCard key={order.sessionID} order={order} />
        ))}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "#F5F7FA"
  },
  scrollView: {
    width
  },
  scrollViewContent: {
    paddingHorizontal: 20,
    paddingBottom: 50
  },
  helpText: {
    marginTop: 20,
    marginBottom: 10
  },
  orderCard: {
    backgroundColor: "white",
    borderRadius: 12,
    padding: 16,
    marginBottom: 16,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3
  },
  orderHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 12
  },
  orderDate: {
    fontSize: 14,
    color: "#A0AEC0"
  },
  orderPrice: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#2DD881"
  },
  productListWrapper: {
    marginBottom: 8
  },
  productItem: {
    marginBottom: 8
  },
  productName: {
    fontSize: 14,
    fontWeight: "500",
    color: "#2D3748"
  },
  productDetails: {
    fontSize: 12,
    color: "#718096"
  },
  productDescription: {
    fontSize: 12,
    color: "#718096",
    marginTop: 2
  },
  questionResponses: {
    marginTop: 12,
    paddingTop: 12,
    borderTopWidth: 1,
    borderTopColor: "#E2E8F0"
  },
  questionResponseItem: {
    marginBottom: 8
  },
  question: {
    fontSize: 14,
    fontWeight: "500"
  },
  response: {
    fontSize: 14,
    color: "#718096",
    marginLeft: 10
  },
  subscriptionBanner: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 12,
    marginTop: 12,
    borderTopWidth: 1,
    borderTopColor: "#E2E8F0"
  },
  subscriptionChargeBanner: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  buttonContainer: {
    flexDirection: "row",
    gap: 8
  },
  subscriptionText: {
    fontSize: 13,
    color: "#4A5568"
  },
  manageButton: {
    backgroundColor: "#2DD881",
    paddingHorizontal: 10,
    paddingVertical: 6,
    borderRadius: 4
  },
  manageButtonText: {
    color: "#FFFFFF",
    fontSize: 12,
    fontWeight: "500"
  },
  receiptButton: {
    backgroundColor: "#F7FAFC",
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 4,
    alignSelf: "flex-start",
    marginTop: 8,
    borderWidth: 1,
    borderColor: "#E2E8F0"
  },
  receiptButtonText: {
    color: "#4A5568",
    fontSize: 12,
    fontWeight: "500"
  },
  priceContainer: {
    flexDirection: "row",
    alignItems: "center"
  },
  subscriptionChargePrice: {
    color: "#718096"
  },
  cancellationBanner: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#FFF5F5",
    padding: 8,
    borderRadius: 4,
    marginBottom: 12
  },
  cancellationText: {
    color: "#E53E3E",
    fontSize: 12,
    fontWeight: "500"
  }
});
