// This is used for:
// 1. Viewing/editing an existing user (manageUser.js)
// 2. Viewing/editing a pending user invitation (manageUser.js)
// 3. Inviting new user(s) (inviteUsers.js)

import React, { useState, useEffect } from "react";
import { StyleSheet, View, ScrollView, Text, Platform } from "react-native";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import Rex from "src/globalState";
import Glob from "src/globalConstants";
import Style from "src/globalStyles";
import Util from "src/utility";
import Multiselect from "src/components/Multiselect";
import ButtonItem from "src/components/dynamicContent/ButtonItem";
import Dropdown from "src/components/Dropdown";
import Button from "src/components/Button";
import InputBox from "src/components/InputBox";
import Checkbox from "src/components/Checkbox";
import AlertModal from "src/components/AlertModal";
import TouchableLink from "src/components/dynamicContent/TouchableLink";
import Icon from "src/components/Icon";

const { width } = Glob.get("dimensions");

const OptionalText = () => (
  <Text style={{ fontWeight: "normal", color: "#aaa" }}> (optional)</Text>
);

export default function UserEditor({
  user: originalUser,
  onUserUpdated = () => {},
  onUserDeleted = () => {},
  navigation,
  isFromChat = false,
  isInvitation = false,
  isNewInvitation = false,
  disableEditing = false
}) {
  const [user, setUser] = useState(originalUser);
  const [internalAdminNote, setInternalAdminNote] = useState(
    originalUser?.internalAdminNote
  );
  const [phoneNumber, setPhoneNumber] = useState(
    originalUser?.phoneNumber || ""
  );
  const [name, setName] = useState(
    `${originalUser?.firstName || ""}${
      originalUser?.firstName && originalUser?.lastName ? " " : ""
    }${originalUser?.lastName || ""}`
  );
  const [email, setEmail] = useState(originalUser?.email || "");
  const [
    phoneNumberIsAUserAccountField,
    setPhoneNumberIsAUserAccountField
  ] = useState(false);
  const [customInputFieldsArray, setCustomInputFieldsArray] = useState([]);
  const [allGroups, setAllGroups] = useState(null);
  const [allGroupIDsInOrder, setAllGroupIDsInOrder] = useState(null);
  const [resentInvitation, setResentInvitation] = useState(false);
  const [alert, setAlert] = useState(null);
  const [allAccountTypes, setAllAccountTypes] = useState(null);
  const [editable, setEditable] = useState(!disableEditing);
  const nameUnsaved =
    name !==
    `${user?.firstName || ""}${
      user?.firstName && user?.lastName ? " " : ""
    }${user?.lastName || ""}`;
  const phoneNumberUnsaved = phoneNumber !== (user?.phoneNumber || "");
  const emailUnsaved = email !== user?.email;
  const phoneCommunicationEnabled =
    !!Rex.getConfig()?.textMessagingEnabled ||
    !!Rex.getConfig()?.phoneCallsEnabled;
  const granularSecurityEnabled = !!Rex.getConfig()?.granularSecurityEnabled;
  const userNotificationsPrivilege = user?.privileges?.PushNotifications;

  // formatted as: { accountTypes: {typeID: true, ...}, groups: {groupID: true, ...} }
  const defaultNotificationPermissions = {
    accountTypes: (Object.keys(allAccountTypes || {}) || []).reduce(
      (acc, key) => {
        acc[key] = true;
        return acc;
      },
      {}
    ),
    groups: (Object.keys(allGroups || {}) || []).reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {})
  };

  useEffect(() => {
    // console.log("user.privileges");
    // console.log(user.privileges);
    Database.fetchAllAccountTypeDetails().then((details) => {
      if (details) setAllAccountTypes(details);
      else {
        // Handle legacy database structure
        Database.getAllDefaultPortals((portals) => {
          const newAllAccountTypes = {};
          Object.keys(portals).forEach((type) => {
            newAllAccountTypes[type] = true;
          });
          setAllAccountTypes(newAllAccountTypes);
        });
      }
    });
    Database.getAllUserGroupsDisplayOrder().then((displayOrder) => {
      setAllGroupIDsInOrder(displayOrder || []);
    });
    Database.getAllUserGroups().then((groups) => setAllGroups(groups || []));
    Database.fetchUserAccountFields().then(({ fields, fieldsArray }) => {
      setPhoneNumberIsAUserAccountField(!!fields?.phoneNumber);
      setCustomInputFieldsArray(
        fieldsArray
          ? fieldsArray.filter((field) => field.key !== "phoneNumber")
          : []
      );
    });
  }, []);

  const userTypeTitle = (type) => {
    return allAccountTypes &&
      allAccountTypes[type] &&
      allAccountTypes[type].title
      ? allAccountTypes[type].title.toLowerCase()
      : type;
  };

  const onSwitchAccountType = (type) => {
    Analytics.logEvent("touch_manageUser_switchAccountType", {
      type,
      user: user?.uid
    });
    const newUser = { ...user, type };
    setUser(newUser);
    onUserUpdated(newUser);
    if (!isNewInvitation) Database.setUserType(type, user.uid, isInvitation);
    if (!isInvitation && !isNewInvitation)
      setAlert({
        title: `Want to also reset their portal configuration to be the default ${userTypeTitle(
          type
        )} portal configuration?`,
        message:
          "This would overwrite the order of everything on this user's homescreen (and might be annoying if they aren't expecting it).",
        confirm: {
          text: "✋ Nope",
          onPress: () => {}
        },
        cancel: {
          text: "💥 Overwrite",
          onPress: () =>
            Database.getDefaultPortalMetadata(type, (portals) =>
              Database.setUserPortals(portals, user.uid)
            )
        }
      });
  };

  const onToggleGroup = (groupId, active) => {
    Analytics.logEvent("touch_manageUser_toggleGroup", {
      groupId,
      active,
      user: user?.uid
    });
    const groups = { ...user.groups };
    if (active) groups[groupId] = true;
    else delete groups[groupId];
    const newUser = { ...user, groups };
    setUser(newUser);
    onUserUpdated(newUser);
    if (!isNewInvitation)
      Database.setUserGroups(Object.keys(groups), user.uid, isInvitation);
  };

  const onTogglePrivilege = (privilegeId, active) => {
    Analytics.logEvent("touch_manageUser_togglePrivilege", {
      privilegeId,
      active,
      user: user?.uid
    });
    const privileges = { ...user.privileges };
    if (active) privileges[privilegeId] = true;
    else delete privileges[privilegeId];
    const newUser = { ...user, privileges };
    setUser(newUser);
    onUserUpdated(newUser);
    if (!isNewInvitation)
      Database.setUserPrivileges(privileges, user.uid, isInvitation);
  };

  const onToggleAllPrivileges = (active) => {
    Analytics.logEvent("touch_manageUser_toggleAllPrivileges", {
      active,
      user: user?.uid
    });
    let privileges = null;
    if (active) {
      privileges = {};
      allAdminPrivileges.forEach((privilege) => {
        privileges[privilege.key] = true;
      });
    }
    const newUser = { ...user, privileges };
    setUser(newUser);
    onUserUpdated(newUser);
    if (!isNewInvitation)
      Database.setUserPrivileges(privileges, user.uid, isInvitation);
  };

  // action: "setToTrue", "setToDefault", "updateCategory"
  // category: "accountTypes", "groups"
  const setPushNotificationPermissions = (
    action = "setToTrue",
    category,
    key,
    value
  ) => {
    const categoryForAnalytics =
      category === "accountTypes" ? { accountType: key } : { group: key };
    Analytics.logEvent("touch_manageUser_setPushNotificationPermissions", {
      action,
      ...categoryForAnalytics,
      value,
      user: user?.uid
    });
    const privileges = { ...user.privileges };
    if (action === "setToDefault") {
      privileges.PushNotifications = { ...defaultNotificationPermissions };
    } else if (action === "setToTrue") {
      privileges.PushNotifications = true;
    } else if (action === "updateCategory" && category && key) {
      if (userNotificationsPrivilege && userNotificationsPrivilege[category]) {
        privileges.PushNotifications[category][key] = value;
      }
    }
    const newUser = { ...user, privileges };
    setUser(newUser);
    onUserUpdated(newUser);
    if (!isNewInvitation)
      Database.setUserPrivileges(privileges, user.uid, isInvitation);
  };

  const onSaveInternalAdminNote = (text) => {
    Analytics.logEvent("touch_manageUser_saveInternalAdminNote", {
      internalAdminNote: text,
      user: user?.uid
    });
    const newUser = { ...user, internalAdminNote: text };
    setUser(newUser);
    onUserUpdated(newUser);
    if (!isNewInvitation)
      Database.setUserInternalAdminNote(text, user.uid, isInvitation);
  };

  const onSaveName = () => {
    Analytics.logEvent("touch_manageUser_saveName", {
      name,
      user: user?.uid
    });
    const [firstName, lastName] = Util.splitUserName(name);
    const newUser = { ...user, firstName, lastName };
    setUser(newUser);
    onUserUpdated(newUser);
    if (!isNewInvitation)
      Database.setUserInvitationName(firstName, lastName, user.uid);
  };

  const onSaveEmail = () => {
    Analytics.logEvent("touch_manageUser_saveEmail", {
      email,
      user: user?.uid
    });
    const newUser = { ...user, email };
    setUser(newUser);
    onUserUpdated(newUser);
    if (!isNewInvitation) Database.setUserInvitationEmail(email, user.uid);
  };

  const onSavePhoneNumber = () => {
    Analytics.logEvent("touch_manageUser_savePhoneNumber", {
      phoneNumber,
      user: user?.uid
    });
    const newUser = { ...user, phoneNumber };
    setUser(newUser);
    onUserUpdated(newUser);
    if (!isNewInvitation)
      Database.setUserPhoneNumber(phoneNumber, user.uid, isInvitation);
  };

  // Only accessible by super admins as of Sep 20, 2022
  const onSetAllNotificationPreferences = () => {
    const preferences = {
      receiveEmailForAll: true,
      receivePushNotifications: true,
      receiveTextMessages: true,
      receivePhoneCalls: true
    };
    let newUser;
    if (user.notifications)
      newUser = {
        ...user,
        notifications: { ...user.notifications, preferences }
      };
    else newUser = { ...user, notifications: { preferences } };
    setUser(newUser);
    onUserUpdated(newUser);
    if (!isNewInvitation)
      Database.setNotificationPreferences(preferences, user.uid, isInvitation);
  };

  // Only accessible by super admins as of Sep 20, 2022
  const onToggleNotificationPreference = (preferenceId, active) => {
    Analytics.logEvent("touch_manageUser_toggleNotificationPreference", {
      preferenceId,
      active,
      user: user?.uid
    });
    const preferences = user.notifications
      ? { ...user.notifications.preferences }
      : {};
    if (active) preferences[preferenceId] = true;
    else delete preferences[preferenceId];
    let newUser;
    if (user.notifications)
      newUser = {
        ...user,
        notifications: { ...user.notifications, preferences }
      };
    else newUser = { ...user, notifications: { preferences } };
    setUser(newUser);
    onUserUpdated(newUser);
    if (!isNewInvitation)
      Database.setNotificationPreferences(
        {
          [preferenceId]: active
        },
        user.uid,
        isInvitation
      );
  };

  const onMessage = () => {
    Analytics.logEvent("touch_manageUser_chatMessage", { user: user?.uid });
    navigation.push("notificationDetails", { chatRecipient: user });
  };

  const onResendUserInvitation = () => {
    Analytics.logEvent("touch_manageUser_resendUserInvitation", {
      user: user?.uid
    });
    if (user?.email) {
      setResentInvitation(true);
      Database.sendInvitationEmails({
        emails: [user.email],
        resendToInvitationKey: user.uid
      });
    }
  };

  const onDeleteUser = () => {
    setAlert({
      title: isInvitation
        ? `Revoke Invitation${user.firstName ? ` for ${user.firstName}` : ""}?`
        : `Delete ${user.firstName}?`,
      message: isInvitation
        ? "This will take away the member's invitation to join this app. Any invitation code they previously received by email will no longer work. This is irreversable, but you can always send them a new invitation."
        : "This will permanently delete the member's account. Next time they open your app, they will be logged out. This is irreversable. Their account will no longer exist in the database.",
      cancel: {
        text: "✋ Cancel",
        onPress: () => {}
      },
      confirm: {
        text: `💥 ${isInvitation ? "Revoke" : "Delete"}`,
        onPress: () => {
          Analytics.logEvent("touch_manageUser_deletedUser", {
            user: user?.uid
          });
          Database.deleteUser(user.uid, isInvitation);
          onUserDeleted(user.uid);
          navigation.goBack();
        }
      }
    });
  };

  if (!allGroups || !user || !allGroupIDsInOrder) return null;

  const groupsCheckboxes = allGroupIDsInOrder.map((groupId) => ({
    key: groupId,
    value: user?.groups && Object.keys(user.groups).indexOf(groupId) > -1,
    text: allGroups[groupId].name
  }));

  const notificationsPermittedGroupsCheckboxes = allGroupIDsInOrder.map(
    (groupId) => ({
      key: groupId,
      value: (userNotificationsPrivilege?.groups || {})[groupId],
      text: allGroups[groupId].name
    })
  );
  const notificationsPermittedAccountTypesCheckboxes = Object.entries(
    allAccountTypes || {}
  ).map(([type, details]) => ({
    key: type,
    value: (userNotificationsPrivilege?.accountTypes || {})[type],
    text: details?.title || type
  }));

  let allAdminPrivileges = Object.entries(
    Glob.get("allAdminPrivileges")
  ).map(([key, val]) => ({ ...val, key }));
  if (!phoneCommunicationEnabled) {
    allAdminPrivileges = allAdminPrivileges.filter(
      (priv) => priv.key !== "TextAndCall"
    );
  }
  if (!Rex.getConfig()?.commerceEnabled) {
    // console.log("HERE");
    allAdminPrivileges = allAdminPrivileges.filter(
      (priv) => priv.key !== "Commerce"
    );
  }
  // console.log("allAdminPrivileges");
  // console.log(allAdminPrivileges);
  const privilegesCheckboxes = allAdminPrivileges.map((privilege) => ({
    key: privilege.key,
    value:
      user?.privileges &&
      Object.keys(user.privileges).indexOf(privilege.key) > -1,
    text: privilege.name
  }));

  const phoneNumberIsValid = (phoneNumber || "").isPhoneNumber();

  const accountTypeDropdownItems = [
    ...Object.entries(allAccountTypes || {}).map(([type, details]) => ({
      value: type,
      text: details?.title || type
    }))
  ];
  if (isNewInvitation)
    accountTypeDropdownItems.unshift({
      text: "Any (let the user pick)",
      value: "ANY_ACCOUNT_TYPE" // This is matched in inviteUsers.js
    });

  const hasCustomFieldValues =
    customInputFieldsArray?.length > 0 &&
    customInputFieldsArray.some((field) => user[field.key]);

  return (
    <View style={styles.pageContent}>
      <ScrollView
        style={{ width: 0.9 * width }}
        scrollIndicatorInsets={{ right: 1 }}
      >
        {!isNewInvitation && (
          <>
            <Text style={[Style.get("headerText"), { marginTop: 15 }]}>
              Contact
            </Text>
            {isInvitation && (
              <InputBox
                placeholder="Name"
                icon="83074fce-adb2-4cd4-bbab-68a02da01ed9" // user
                value={name}
                style={{ marginTop: 0 }}
                onChangeText={setName}
                editable={editable}
              />
            )}
            {nameUnsaved && (
              <Button
                text="Save name"
                flat
                small
                color="#2DD881"
                onPress={onSaveName}
              />
            )}
            <InputBox
              placeholder="Email"
              icon="email"
              value={
                isInvitation
                  ? email
                  : `${user?.email} (set by ${user?.firstName || "user"})`
              }
              style={{ marginTop: 0 }}
              editable={editable && !!isInvitation}
              onChangeText={setEmail}
              isInvalid={emailUnsaved && !email.isEmail()}
              isValid={emailUnsaved && email.isEmail()}
              keyboardType="email-address"
            />
            {emailUnsaved && (
              <>
                {email.isEmail() ? (
                  <Button
                    text="Save email"
                    flat
                    small
                    color="#2DD881"
                    onPress={onSaveEmail}
                  />
                ) : (
                  <Text style={{ color: "#F03738", textAlign: "center" }}>
                    Email is invalid
                  </Text>
                )}
              </>
            )}
            {(phoneCommunicationEnabled ||
              phoneNumberIsAUserAccountField ||
              phoneNumber?.length > 0) && (
              <InputBox
                placeholder="Phone Number"
                icon="eacd053e-5dca-4e7d-beff-a65bcab765c6" // phone
                value={`${phoneNumber || ""}`}
                onChangeText={setPhoneNumber}
                isInvalid={
                  phoneNumber && phoneNumberUnsaved && !phoneNumberIsValid
                }
                isValid={
                  phoneNumber && phoneNumberUnsaved && phoneNumberIsValid
                }
                keyboardType="phone-pad"
                editable={editable}
              />
            )}
            {phoneNumberUnsaved && (
              <>
                {phoneNumberIsValid || !phoneNumber ? (
                  <Button
                    text="Save phone number"
                    flat
                    small
                    color="#2DD881"
                    onPress={onSavePhoneNumber}
                  />
                ) : (
                  <Text style={{ color: "#F03738", textAlign: "center" }}>
                    Phone number is invalid
                  </Text>
                )}
              </>
            )}
            {editable && (
              <View style={{ marginTop: 10 }}>
                {!!Rex.getConfig()?.chatEnabled && !isFromChat && (
                  <>
                    {isInvitation ? (
                      <>
                        {resentInvitation ? (
                          <Text
                            style={{
                              textAlign: "center",
                              marginTop: 10,
                              marginBottom: 20,
                              color: "#aaa"
                            }}
                          >
                            Sent another invitation email
                            {user.firstName ? ` to ${user.firstName}` : ""} 👍
                          </Text>
                        ) : (
                          <Button
                            icon="send"
                            text="Resend Invitation"
                            onPress={onResendUserInvitation}
                          />
                        )}
                      </>
                    ) : (
                      <Button text="Message" onPress={onMessage} />
                    )}
                  </>
                )}
                <ButtonItem
                  item={{
                    type: "button_email",
                    email: user?.email,
                    title: "Email",
                    small: true,
                    flat: true,
                    outline: true
                  }}
                />
                {!!phoneNumber && phoneNumberIsValid && Platform.OS !== "web" && (
                  <>
                    <ButtonItem
                      item={{
                        type: "button_call",
                        number: phoneNumber,
                        title: "Call",
                        small: true,
                        flat: true,
                        outline: true
                      }}
                    />
                    <ButtonItem
                      item={{
                        type: "button_sms",
                        recipient: phoneNumber,
                        title: "Text",
                        small: true,
                        flat: true,
                        outline: true
                      }}
                    />
                  </>
                )}
              </View>
            )}
          </>
        )}

        {hasCustomFieldValues && (
          <View>
            <Text style={[Style.get("headerText"), { marginTop: 15 }]}>
              More Info
            </Text>
            {customInputFieldsArray.map((field) => {
              const { key, title, icon } = field;
              if (!user[key]) return null;
              return (
                <View
                  style={{
                    flexDirection: "row",
                    marginVertical: 6,
                    alignItems: "center"
                  }}
                >
                  <Icon
                    icon={icon}
                    color="#868686"
                    size={24}
                    style={{ marginHorizontal: 8 }}
                  />
                  <Text style={{ color: "#868686" }}>
                    <Text style={{ fontWeight: "bold" }}>{title}: </Text>
                    {user[key]}
                  </Text>
                </View>
              );
            })}
          </View>
        )}

        <Text style={[Style.get("headerText"), { marginTop: 15 }]}>
          Account Type{isNewInvitation ? <OptionalText /> : ""}
        </Text>
        {!!allAccountTypes && (
          <Dropdown
            value={user.type}
            items={accountTypeDropdownItems}
            onSelect={onSwitchAccountType}
            disabled={!editable}
          />
        )}
        <Text style={[Style.get("headerText"), { marginTop: 15 }]}>
          Groups{isNewInvitation ? <OptionalText /> : ""}
        </Text>
        {groupsCheckboxes.length > 0 ? (
          <Multiselect
            items={groupsCheckboxes}
            onToggle={(key, value) => onToggleGroup(key, value)}
            editable={editable}
          />
        ) : (
          <Text>Your app has no groups configured.</Text>
        )}
        <Text style={[Style.get("headerText"), { marginTop: 15 }]}>
          Administrative Superpowers{isNewInvitation ? <OptionalText /> : ""}
        </Text>
        <Checkbox
          iconColor="black"
          checked={allAdminPrivileges.every(
            (privilege) =>
              privilege.key in (user.privileges || {}) &&
              user.privileges[privilege.key] === true
          )}
          text={<Text style={{ fontWeight: "bold" }}>All superpowers</Text>}
          onChange={onToggleAllPrivileges}
          disabled={!editable}
        />
        <Multiselect
          items={privilegesCheckboxes}
          onToggle={(key, value) => onTogglePrivilege(key, value)}
          editable={editable}
        />
        {granularSecurityEnabled && !!userNotificationsPrivilege && editable && (
          <>
            {userNotificationsPrivilege === true ? (
              <Button
                text="Limit Notification Permissions"
                outline
                flat
                onPress={() => setPushNotificationPermissions("setToDefault")}
              />
            ) : (
              <>
                <Text style={[Style.get("headerText"), { marginTop: 15 }]}>
                  Notification Permissions
                </Text>
                <Text style={{ marginTop: 6 }}>
                  <Text style={{ fontWeight: "bold" }}>Account Types</Text>
                  {user.firstName ? ` that ${user.firstName} is ` : " they're "}
                  allowed to send to:
                </Text>
                {notificationsPermittedAccountTypesCheckboxes.length > 0 && (
                  <Multiselect
                    items={notificationsPermittedAccountTypesCheckboxes}
                    onToggle={(key, value) =>
                      setPushNotificationPermissions(
                        "updateCategory",
                        "accountTypes",
                        key,
                        value
                      )
                    }
                    editable={editable}
                  />
                )}
                <Text style={{ marginTop: 6 }}>
                  <Text style={{ fontWeight: "bold" }}>Groups</Text>
                  {user.firstName ? ` that ${user.firstName} is ` : " they're "}
                  allowed to send to:
                </Text>
                {notificationsPermittedGroupsCheckboxes.length > 0 ? (
                  <Multiselect
                    items={notificationsPermittedGroupsCheckboxes}
                    onToggle={(key, value) =>
                      setPushNotificationPermissions(
                        "updateCategory",
                        "groups",
                        key,
                        value
                      )
                    }
                    editable={editable}
                  />
                ) : (
                  <Text style={{ fontStyle: "italic" }}>
                    (No groups have been created yet)
                  </Text>
                )}
                <Button
                  text="Allow All Notification Permissions"
                  outline
                  flat
                  onPress={() => setPushNotificationPermissions("setToTrue")}
                />
              </>
            )}
          </>
        )}
        <View
          style={{
            backgroundColor: "#eee",
            marginTop: 30,
            padding: 15,
            paddingTop: 0
          }}
        >
          <InputBox
            header="Internal Notes"
            showAsOptional={isNewInvitation}
            description="These member-specific notes are only visible to you and other app managers."
            multiline
            value={internalAdminNote}
            onChangeText={(text) => {
              setInternalAdminNote(text);
              if (isNewInvitation) onSaveInternalAdminNote(text);
            }}
            editable={editable}
          />
          {internalAdminNote !== user.internalAdminNote && (
            <Button
              text="Save note"
              small
              flat
              onPress={() => onSaveInternalAdminNote(internalAdminNote)}
            />
          )}
        </View>
        {!isNewInvitation && (
          <View style={{ marginVertical: 20 }}>
            {editable && (
              <Button
                text={isInvitation ? "Revoke Invitation" : "Delete Member"}
                outline
                color={Glob.get("dangerRed")}
                onPress={onDeleteUser}
              />
            )}
            {Database.userIsSuperAdmin() && (
              <View style={{ backgroundColor: "#eee", marginTop: 30 }}>
                <Text style={{ fontWeight: "bold", fontSize: 20, margin: 10 }}>
                  🕶 Top Secret! Everything in this box is only visible to super
                  admins:
                </Text>
                {!!user?.uid && (
                  <>
                    <TouchableLink
                      type="web"
                      navigation={navigation}
                      link={`https://analytics.amplitude.com/seabird-apps/project/345593/search/${user?.uid}`}
                      text="📈 Amplitude Link"
                      textStyle={{
                        marginVertical: 15,
                        textAlign: "center",
                        fontSize: 20,
                        textDecorationLine: "underline"
                      }}
                    />
                    <Button
                      text="App Progress"
                      onPress={() =>
                        navigation.push("tutorial", { userID: user.uid })
                      }
                    />
                  </>
                )}
                {!editable && (
                  <Button
                    text="Edit This Member"
                    onPress={() => setEditable(true)}
                  />
                )}
                <Text
                  style={{ textAlign: "center", marginTop: 15, fontSize: 20 }}
                >
                  Devices:
                </Text>
                {user?.devices ? (
                  <>
                    {Object.values(user.devices).map((device) => (
                      <View
                        style={{
                          backgroundColor: {
                            ios: "silver",
                            web: "lightblue",
                            android: "lightgreen",
                            unknown: "pink"
                          }[device?.platformOS || "unknown"],
                          borderRadius: 10,
                          margin: 10,
                          padding: 10
                        }}
                      >
                        {Object.entries(device).map(([key, value]) => (
                          <Text>
                            {key}: {value}
                          </Text>
                        ))}
                      </View>
                    ))}
                  </>
                ) : (
                  <Text style={{ textAlign: "center", marginBottom: 15 }}>
                    (None tracked yet)
                  </Text>
                )}
                <Text style={{ textAlign: "center", fontSize: 20 }}>
                  Notification Preferences:
                </Text>
                {!user?.notifications?.preferences ? (
                  <Button
                    text="Set Preferences"
                    outline
                    small
                    onPress={onSetAllNotificationPreferences}
                  />
                ) : (
                  <Multiselect
                    items={[
                      {
                        key: "receiveEmailForAll",
                        value:
                          !user?.notifications?.preferences ||
                          user?.notifications?.preferences?.receiveEmailForAll,
                        text: "Receive emails"
                      },
                      {
                        key: "receivePushNotifications",
                        value:
                          !user?.notifications?.preferences ||
                          user?.notifications?.preferences
                            ?.receivePushNotifications,
                        text: "Receive push notifications"
                      },
                      {
                        key: "receiveTextMessages",
                        value:
                          !user?.notifications?.preferences ||
                          user?.notifications?.preferences?.receiveTextMessages,
                        text: "Receive text messages"
                      },
                      {
                        key: "receivePhoneCalls",
                        value:
                          !user?.notifications?.preferences ||
                          user?.notifications?.preferences?.receivePhoneCalls,
                        text: "Receive phone calls"
                      }
                    ]}
                    onToggle={(key, value) =>
                      onToggleNotificationPreference(key, value)
                    }
                  />
                )}
                <Text style={{ textAlign: "center", fontSize: 20 }}>
                  Legacy Push Token:
                </Text>
                {user?.pushToken ? (
                  <Text style={{ textAlign: "center", marginBottom: 15 }}>
                    {user.pushToken}
                  </Text>
                ) : (
                  <Text style={{ textAlign: "center", marginBottom: 15 }}>
                    (None)
                  </Text>
                )}
              </View>
            )}
          </View>
        )}
      </ScrollView>
      <AlertModal alert={alert} setAlert={setAlert} />
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  }
});
